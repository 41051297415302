import { Menu, MenuItem, MenuSeparator } from '@codecademy/gamut';
import { GamutIconProps } from '@codecademy/gamut-icons';
import * as React from 'react';

import { DropdownButtonProps } from '.';

type AnchorProps = Partial<
  Pick<HTMLAnchorElement, 'href' | 'target' | 'ariaLabel'>
>;

export type DropdownItem = {
  id: string;
  icon?: React.ComponentType<GamutIconProps>;
  text: string;
  clickHandler?: (event: React.MouseEvent) => void;
} & AnchorProps;

export type DropdownListProps = {
  dropdownItems: (DropdownItem | 'separator')[];
  menuButtonId?: string;
  onClose?: () => void;
} & Pick<DropdownButtonProps, 'isMenu'>;

export const DropdownList: React.FC<DropdownListProps> = ({
  dropdownItems,
  isMenu,
  menuButtonId,
  onClose,
}) => {
  const listRefs = React.useRef<React.RefObject<HTMLAnchorElement>[]>(
    dropdownItems.map(() => React.createRef())
  );

  const handleKeyDown = (
    event: React.KeyboardEvent | React.KeyboardEvent,
    index: number
  ) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      onClose?.();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (index > 0) {
        const isSeparatorEl = dropdownItems[index - 1] === 'separator';
        const decrementVal = isSeparatorEl ? 2 : 1;
        // Move focus to the previous item in the list
        listRefs.current[index - decrementVal].current?.focus();
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (index < dropdownItems.length - 1) {
        const isSeparatorEl = dropdownItems[index + 1] === 'separator';
        const incrementVal = isSeparatorEl ? 2 : 1;
        // Move focus to the next item in the list
        listRefs.current[index + incrementVal].current?.focus();
      }
    }
  };

  return (
    <Menu
      variant="popover"
      border="none"
      minWidth="max-content"
      py={4}
      role={isMenu ? 'menu' : undefined}
      aria-labelledby={menuButtonId || ''}
    >
      {dropdownItems.map((item, i) => {
        if (item === 'separator') {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuSeparator key={`dropdown-separator-${i}`} aria-hidden="true" />
          );
        }

        const { id, text, clickHandler, icon, ...linkProps } = item;

        const onClick = (event: React.MouseEvent) => {
          clickHandler?.(event);
          onClose?.();
        };

        return (
          <MenuItem
            key={id}
            data-testid={`dropdown-item-${id}`}
            onClick={onClick}
            icon={icon}
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(e, i)}
            ref={listRefs.current[i]}
            {...linkProps}
          >
            {text}
          </MenuItem>
        );
      })}
    </Menu>
  );
};
