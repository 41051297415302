import { ButtonBaseElements } from '@codecademy/gamut';
import { useContext, useRef, useState } from 'react';

import { CertPathContext } from '../..';
import { AnimatedHeaderZone } from '../shared';
import { SearchButton } from './SearchButton';
import { SearchPane } from './SearchPane';
import { AppHeaderSearch } from './types';

export const useHeaderSearch = ({
  onEnable,
  onSearch,
  onTrackingClick,
  onSearchAsYouType,
}: AppHeaderSearch) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchButtonRef = useRef<ButtonBaseElements>(null);

  const { newCertPathsEnabled } = useContext(CertPathContext);

  const toggleSearch = () => {
    if (!isSearchVisible) {
      onEnable();
    }
    setIsSearchVisible((oldIsSearchVisible) => !oldIsSearchVisible);
  };

  return [
    {
      id: 'search',
      type: 'render-element',
      renderElement: () => (
        <SearchButton
          isSearchVisible={isSearchVisible}
          setIsSearchVisible={setIsSearchVisible}
          toggleSearch={toggleSearch}
          searchButtonRef={searchButtonRef}
        />
      ),
    },
    <AnimatedHeaderZone visible={isSearchVisible} key="search-content">
      <SearchPane
        onSearch={onSearch}
        onTrackingClick={onTrackingClick}
        toggleSearch={toggleSearch}
        searchButtonRef={searchButtonRef}
        onSearchAsYouType={onSearchAsYouType}
        newCertPathsEnabled={newCertPathsEnabled}
      />
    </AnimatedHeaderZone>,
  ] as const;
};
